<template>
    <div>
        <page-title :heading="$t('generic.lang_nav_help')" :subheading="$t('generic.lang_nav_help')" :icon=icon></page-title>
        <div class="app-main__inner">
                        <Help></Help>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import Help from "../../components/help/Help";

    export default {
        components: {
            PageTitle,
            Help
        },

        data: () => ({
            icon: 'pe-7s-help1 icon-gradient bg-tempting-azure',
        }),
    }
</script>