<template>
  <v-container fluid>
    <h4 class="text-muted">{{ $t('generic.lang_downloadSoftware') }}</h4>
    <p>{{ $t('generic.lang_softwareDownloadBody') }}</p>
    <v-btn class="text-decoration-none" color="primary" href="https://cloud.3pos.de/f/39e7ddc52c6349ffb88d/?dl=1" target="_blank">{{ $t('generic.lang_download3pos') + " "}}<font-awesome-icon :icon="['fad', 'download']" class="mx-2"/></v-btn><br/>
    <v-btn class="text-decoration-none" color="primary" href="https://cloud.3pos.de/f/2186949d4f99430d9fe7/?dl=1" target="_blank">{{ $t('generic.lang_downloadESCPOSServer') + " "}}<font-awesome-icon :icon="['fad', 'download']" class="mx-2"/></v-btn><br/>
    <v-btn class="text-decoration-none" color="primary" href="https://cloud.3pos.de/f/f4fde7672c4c4a37b14c/?dl=1" target="_blank">{{ $t('generic.lang_downloadZVTServer') + " "}}<font-awesome-icon :icon="['fad', 'download']" class="mx-2"/></v-btn>
  </v-container>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faDownload
} from '@fortawesome/pro-duotone-svg-icons'
library.add(faDownload);

export default {
  name: "DownloadSoftware",
  components:{
    FontAwesomeIcon
  }
}
</script>

<style scoped>

</style>