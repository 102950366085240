<template>
  <div>
    <h2>{{$t('generic.lang_scaleCertification')}}</h2>
    <div v-html="$t('generic.lang_scaleConformityBody')"></div>
  </div>
</template>

<script>
export default {
  name: "ScaleConfirmity",
};
</script>
